import React, { useState, useEffect } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { Navigate, useLocation } from 'react-router-dom';
import CourseFiltersBenchmark from 'components/dashboards/e-learning/CourseFiltersBenchmark';
import PropTypes from 'prop-types';
// import { totalSales } from 'data/dashboard/default';
import BackDrop from 'components/templates/BackDrop';
import UniversityProjects from 'components/dashboards/default/UniversityProjects';
// import TableModal from 'components/dashboards/futura/TableModal';
// import { EModal } from 'components/templates/Modal';
import { useDefault } from 'hooks';

const Benchmark = ({ authorized }) => {
  if (!authorized) return <Navigate to="/dashboard" />;
  const location = useLocation();
  const { list } = useDefault();
  const [dimension, setDimension] = useState([]);
  const [filter, setFilter] = useState(true);
  const [search, setSearch] = useState(false);
  const [items, setItems] = useState();

  useEffect(() => {
    if (location.pathname === '/dashboard/benchmark') getUser_university();
  }, [location]);

  const getUser_university = () => {
    list('dashboard_nps/data_select').then(data => {
      data && setItems(data);
    });
  };

  if (items === undefined) return <BackDrop param={' '} />;
  if (
    items !== undefined
    // numUniversities?.cities?.length > 0
  )
    return (
      <>
        <Row className="g-3 mb-3">
          <Col xl={3}>
            <CourseFiltersBenchmark
              data={items}
              setDimension={setDimension}
              setFilter={setFilter}
              setSearch={setSearch}
            />
          </Col>
          <Col xl={9}>
            {search && <BackDrop param={' '} />}
            {!search && (
              <Row style={{ marginTop: '10px' }}>
                {Array.isArray(dimension) &&
                  dimension.map(item => (
                    <React.Fragment key={item.id}>
                      <Alert.Heading
                        style={{
                          marginTop: '10px',
                          display: 'flex',
                          justifyContent: 'center', // Centra horizontalmente
                          alignItems: 'center', // Centra verticalmente
                          height: '100%' // Asegura que ocupe toda la altura del contenedor
                        }}
                      >{`Dimensión: ${item.title} ${item.duration}%`}</Alert.Heading>
                      <Col lg="6" style={{ marginTop: '10px' }}>
                        <UniversityProjects
                          data={item.universities}
                          title={`Universidad vs Dimensión`}
                          icon="user-graduate"
                        />
                      </Col>
                    </React.Fragment>
                  ))}
                {Array.isArray(dimension) &&
                  dimension.length === 0 &&
                  filter && (
                    <Alert.Heading
                      style={{
                        marginTop: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                      }}
                    >
                      Debe seleccionar hasta 5 universidades, 1 modalidad y 1
                      nivel académicos!!!
                    </Alert.Heading>
                  )}
                {Array.isArray(dimension) &&
                  dimension.length === 0 &&
                  !filter && (
                    <Alert.Heading
                      style={{
                        marginTop: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                      }}
                    >
                      No existen calificaciones para mostrar!!!
                    </Alert.Heading>
                  )}
              </Row>
            )}
          </Col>
        </Row>
      </>
    );
};

Benchmark.propTypes = {
  authorized: PropTypes.bool
};

export default Benchmark;
